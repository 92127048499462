import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_CLEAN_ERROR
} from '../actions/types'

const initialState = {
  loading: false,
  error: null,
  liveActions: []
};

const asyncReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ASYNC_ACTION_START:
     state.liveActions.push(payload)
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ASYNC_ACTION_FINISH:
      state.liveActions = state.liveActions.filter( act => act != payload)
      return {
        ...state,
        loading: false
      };
    case ASYNC_ACTION_ERROR:
      state.liveActions = state.liveActions.filter( act => act != payload)
      return {
        ...state,
        loading: false,
        error: payload
      };
    case ASYNC_ACTION_CLEAN_ERROR:
      return {
        ...state,
        error: undefined
      };
    default:
      return state;
  }
};

export default asyncReducer;
